$('body').on('submit', 'form[name=delete-item]', function(e) {
    e.preventDefault();

    Swal.fire({
        title: 'Are you sure you want to delete this item?',
        showCancelButton: true,
        confirmButtonText: 'Confirm Delete',
        cancelButtonText: 'Cancel',
        icon: 'warning'
    }).then((result) => {
        if (result.value) {
            this.submit()
        }
    });
})
